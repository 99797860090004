.fluencyTitle {
  font-size: 6rem;
  font-family: 'Dancing Script', cursive;

  @media screen and (max-width: 600px) {
    font-size: 2.3rem;
  }
}

.fluencySubtitle {
  font-size: 2.0rem;
  font-family: 'Roboto', sans-serif;

  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
  }
}
